import { useContext, useLayoutEffect, useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import commonLib from "../../components/control/commonLib";
import konsole from "../../components/control/Konsole";
import { $CommonServiceFn } from "../../components/network/Service";
import { $Service_Url } from "../../components/network/UrlPath";
import config from '../../config.json'
import Invalid from '../../components/Account/Invalid'
import HeaderImage from "../../components/Account/HeaderImage";
import { UserContext } from "../../App";
import { successMsg } from "../../components/control/Constant";

export default function Activation() {
  const navigate = useNavigate();
  const { setWarning } = useContext(UserContext);

  const [ error, setError ] = useState(null);
  const [ errorInvalid, seterrorInvalid ] = useState(null);
  const [frstRender, setfrstRender] = useState(false);

  useEffect(() => {
      try {
        if(frstRender) {
          const UID = window.atob(commonLib.getQueryFromUrl("UID"));
          const SUP = window.atob(commonLib.getQueryFromUrl("SUP"));    
          const splittedArray = UID.split("|");
          konsole.log("Activation Link", splittedArray, " SUP-", SUP, " UID-", UID);
          if(splittedArray.length === 3){
            validateUserByLink(splittedArray[0], splittedArray[1], splittedArray[2], SUP, "::1")
          }
        }
        setfrstRender(true);
      } catch (err) {
        konsole.log("sbkjad", err)
        seterrorInvalid(true);
      }
      return () => {
        setfrstRender(false);
        // console.clear();
      };
    }, [frstRender]);



  const validateUserByLink = (activationKey, userRegstrtnId, userId, signUpPlatform, clientIPAddress) => {

      const validateJsonData = {
        userRegstrtnId: parseInt(userRegstrtnId),
        userId: userId,
        activationKey: activationKey,
        signUpPlatform: parseInt(signUpPlatform),
        clientIPAddress: clientIPAddress, 
      }

    $CommonServiceFn.InvokeCommonApi("POST", $Service_Url.validateUserByLink, validateJsonData,(response, error)=> {
        if(response){
          const responseData = response.data.data;
          if (responseData !== null) {
            if(responseData.isValidLink == true){
              let otpSendMedium = responseData.otpSendMedium;
              const subtenantId = responseData.subtenantId;
              const createdBy = responseData.userId;
              
              if (responseData.primaryPhoneNumber !== null && responseData.primaryEmailId){
                otpSendMedium = config.OTPMEDIUMBOTH 
                validateJsonData["emailUserId"] = responseData.primaryEmailId;
              }
              else if (responseData.primaryPhoneNumber !== null) {
                otpSendMedium = config.OTPMEDIUMSMS
              }
              else if (responseData.primaryEmailId !== null) {
                otpSendMedium = config.OTPMEDIUMEMAIL;
                validateJsonData["emailUserId"] = responseData.primaryEmailId;
              }
              
              const postData = {
                "createdBy": createdBy,
                "otpSendMedium": otpSendMedium,
                "subtenantId": subtenantId,
                "otpType": config.USERACTIVATION
              }
              konsole.log("response of validate", response);
             
              validateJsonData["userActivationId"] = responseData.userActivationId;
              commonLib.setSessionStorage("userCred", JSON.stringify(validateJsonData))
              konsole.log("response of validate", response, JSON.stringify(postData));
              $CommonServiceFn.InvokeCommonApi("POST", $Service_Url.postSendOtp, postData, (resp, error) => {
                if (resp) {
                  setWarning('success', successMsg.otpSent);
                  konsole.log("otp send resp", resp);
                  let createId = resp.data.data.createdBy;
                  let Id = resp.data.data.id;
                  let otpSendMedium = resp.data.data.otpSendMedium;
                  let otpSendOn = resp.data.data.otpSendOn;
                  let senddata = response.data.data;
                  konsole.log('idd', Id);
                  
                  sessionStorage.setItem('remainingTime','');
                  commonLib.resetSS4Otp();
                  navigate(`/Account/verifyOtp?subtenantId=${subtenantId}`, {
                    state: {
                      createId: createId,
                      Id: Id,
                      otpSendOn: otpSendOn,
                      otpSendMedium: otpSendMedium,
                      subtenantId: subtenantId,
                      senddata:senddata,
                      signUpPlateform: parseInt(signUpPlatform),
                      typeOfPage:'activation'
                    }
                  })
                }
                else if (error) {
                  konsole.log("error", error)
                }
              })
            }
          }
        }
        else if(error){
          konsole.log("error validate", error, error?.data?.messages?.[0]);
          if(error?.data?.messages?.[0] == "Invalid Link") setError(true)
          else seterrorInvalid(true);
        }
      })
    }


  return (
    
    <div id="activation">
    <div className='headerImg' style={{margin :"0 auto"}}>
      <HeaderImage/>
    </div>
    <div className="vertically-center12">
      {
        (errorInvalid !== null) &&
        <Invalid message= "This link is invalid" type={"invalid"}/>
      }
      {
        (error !== null) &&
        <Invalid message= "This link is expired" type={"expired"}/>
      }
      </div>
    </div>
  )
}
